import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import patterns from "./store/patterns";
import "./assets/css/style.css";
import "./plugins/axios";
// import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import Notifications from "vue-notification";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueTimeago from "vue-timeago";
import VCountryRegionSelect from "@timbouc/vuetify-country-region-input";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import i18n from "./plugins/i18n";
import InfiniteLoading from "vue-infinite-loading";
import VueToastr from '@deveodk/vue-toastr'
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'
import io from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import JwPagination from 'jw-vue-pagination';
import tinymce from 'vue-tinymce-editor'
Vue.component('tinymce', tinymce)
Vue.component('jw-pagination', JwPagination);
// ./src/main.js





Vue.use(
  new VueSocketIO({
    debug: true,
    // https://3erabest.com
    connection: io("wss://sp0mers-team.herokuapp.com/", {
      // autoConnect: false,
      extraHeaders: {
        "x-auth-token": store.getters.getToken,
      },
      // transports: ["websocket", "polling"],
    }),

    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
Vue.prototype.soundEffect = new Audio(
  "https://mobcup.net/va/FfKvpz8HWZLYUih5Z"
);
Vue.prototype.Getlang = localStorage.getItem("locale");
Vue.use(VueToastr)
Vue.use(InfiniteLoading, {
  props: { spinner: "spiral" },
  slots: {
    noMore: "",
    error: "Oops, Something went wrong please try again "
  }
});


Vue.use(VueSweetalert2);

Vue.use(VCountryRegionSelect);
Vue.use(VueTimeago, {
  name: "timeago", // Component name, `timeago` by default
  locale: "en", // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    en: require("date-fns/locale/en"),
    ar: require("date-fns/locale/ar"),
  },
});
Vue.use(Loading);
Vue.use(Notifications);
Vue.prototype.$pattern = patterns;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
