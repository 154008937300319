<template>
  <v-app>
    <notifications :duration="3000" position="top left" group="error">
    </notifications>
    <notifications :duration="3000" position="top center" group="info" max="1" >
    </notifications>
    <notifications :duration="3000" position="bottom left" group="success">
    </notifications>
 <v-alert v-if="typing" class="mmmm" border="right" elevation="4" icon="mdi-dots-horizontal" type="success"
        ><div >{{ $t("globals.typing") }}</div></v-alert
      >
    <v-main>
      <firstLocal />
      <Nav />
      <div style="height:10px"></div>
      <div class="homepage">
        <keep-alive :exclude="['youscama']" >
          <router-view :key="$route.fullPath"></router-view>
          <!-- <router-view></router-view> -->
        </keep-alive>
      </div>
      <!-- <div class="homepage" v-else>
        v-if="$vuetify.rtl === true"
        <router-view></router-view>
      </div> -->

      <!-- <AuthLayout></AuthLayout> -->
      <div style="height:100px"></div>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Nav from "./components/navbar/navbar";
import Footer from "./components/navbar/footer";
import firstLocal from "./components/firstlocal";

export default {
  name: "App",

  components: {
    Nav,
    Footer,
    firstLocal,
  },

  data: () => ({
    typing: false
  }),
  // sockets: {
  //   connect: function() {
  //     console.log("socket connected");
  //   },

  //   recev: function(data) {
  //      this.$notify({
  //       group: "info",
  //       type: "info",
  //       title: "succefully",
  //       text: this.$t('globals.sucscama1'),
  //     });
  //     this.soundEffect.play();
  //     // this.updateData();
  //     console.log(data)
  //   },
  //   onlineuser: function(data) {
  //     this.onlineUser.push(data);
  //   },
  //   oflineuser: function(data) {
  //     this.onlineUser.shift(data);
  //   },

  //   // connect_error: async (err) => {
  //   //   console.log(err);
  //   //   // this.logout;
  //   // },

  //   typing(data) {
  //     this.typing = true;

  //     this.typs = data.nickname;
  //     this.timeOutId = setTimeout(() => {
  //       this.typing = false;
  //     }, 4000);
  //     return data;
  //   },
  //    },
  beforeUpdate: function() {
    const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
    this.$http
      .get("/checkdata", {
        headers: {
          Authorization: AuthStr,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err.message);
        if (this.$store.getters.isLoggedIn) {
           this.$toastr("add", {
              title: "errors",
              msg: this.$t("globals.errcon"),
              timeout: 3000,
              position: "toast-top-center",
              type: "error",
              closeOnHover: true,
              clickClose: false,
            });
          this.$store.dispatch("destroySession");
        }
      });
  },
  mounted(){
      this.$socket.emit("joinGroup", this.$store.getters.getUserId);
  }
};
</script>
<style>
/* .theme--light.v-application {
  background-color: black;

  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.3s;
} */
.theme--dark.v-application {
  background-color: #15202b;
}
.theme--light.v-application {
  background-color: #dde0e0;
}
.mmmm {
  position: fixed;
  top: 70px;
  right: 20;
  z-index: 99999;
}
</style>
