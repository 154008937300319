<template>
  <div>
    <v-app-bar
      height="50%"
      v-bind="localAttrs"
      bg
      class="appbarback"
      color="miro"
    >
      <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> -->
      <router-link to="/" >
        <v-app-bar-nav-icon ><v-icon class="maincolor2--text">mdi-podcast</v-icon> </v-app-bar-nav-icon>
        <!-- <v-toolbar-title color="maincolor1">
          {{ $t("nav.home") }}</v-toolbar-title
        > -->
      </router-link>
      <v-spacer></v-spacer>

      <Locals />

      <v-btn
        text
        fab
        @click="darkModeToggle"
        v-bind="attrs"
        v-on="on"
        height="0"
      
      >
        <v-icon color="maincolor1">{{ icon }}</v-icon>
      </v-btn>

      <div v-if="!$store.getters.isLoggedIn" style="font-size: 11px">
        <!-- <router-link to="/login">
          <v-btn rounded text fab x-small class="pa-2">
            {{ $t("nav.signin") }}
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-btn>
        </router-link> -->
        <router-link to="/register" class="ml-3 mr-3">
          <v-btn rounded text fab x-small class="pa-2">
            {{ $t("nav.signup") }}
            <v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-btn>
        </router-link>
      </div>

      <div v-else>
        <strong class="grey--text pa-3 ma-2" style="font-size: 11px">
          {{ this.$store.getters.getUserName }}
        </strong>
        <v-btn text fab @click="logout" height="0" width="0">
          <v-icon>mdi-power</v-icon>
        </v-btn>
      </div>

      <v-alert v-if="typing" class="mmmm" border="right" elevation="4" icon="mdi-dots-horizontal" type="success"
        ><div >{{ $t("globals.typing") }}</div></v-alert
      >
    </v-app-bar>
     
    <!-- <div v-if="$store.getters.isLoggedIn">
      <AuthLayout
        v-model="drawer"
        absolute
        bottom
        class="hidden-xs-and-down hidden-md-and-down"
        temporary
      ></AuthLayout>
      <Mobiledrawer class="hidden-sm-and-up hidden-xs-and-up" />
    </div> -->
    <br/><br/>
    
  </div>
</template>
<style scoped>
.appbarback {
  background-color: #008dbd;
}
</style>
<script>
// import AuthLayout from "../../layout/auth/drawer";
// import Mobiledrawer from "../../layout/auth/drawermobile";
import Locals from "@/components/locale";
export default {
  components: {
    // AuthLayout,
    // Mobiledrawer,
    Locals,
  },
  props: ["HR"],
  data: () => ({
    icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
    items: ["default", "absolute", "fixed"],
    padless: false,
    variant: "fixed",
    drawer: true,
    group: null,
    icon: "",
    darkMode: false,
    attrs: true,
    typing: false,
  }),
  // sockets: {
  //   connect: function () {
  //     console.log("socket connected");
  //   },

  //   recev: function (data) {
  //     //    var audio = new Audio("https://mobcup.net/v/kwrvf478")
  //     // audio.play()
  //     // bus.$emit('someEvent', this.someObject);
  //     this.$emit("ll", { data });
  //     this.$notify({
  //       group: "info",
  //       type: "info",
  //       title: "succefully",
  //       text: this.$t('globals.sucscama1'),
  //     });
  //     this.soundEffect.play();
  //   },

  //   // connect_error: async (err) => {
  //   //   console.log(err);
  //   //   // this.logout;
  //   // },

  //   typing(data) {
  //     this.typing = true;

  //     this.typs = data.nickname;
  //     this.timeOutId = setTimeout(() => {
  //       this.typing = false;
  //     }, 4000);
  //     return data;
  //   },
  // },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    localAttrs() {
      const attrs = {};

      if (this.variant === "default") {
        attrs.absolute = false;
        attrs.fixed = false;
      } else {
        attrs[this.variant] = true;
      }
      return attrs;
    },
  },
  methods: {
    async logout() {
      this.$socket.close();
      await this.$store.dispatch("destroySession");
    },
    enableDarkMode() {
      this.$vuetify.theme.dark = true;
      this.icon = "mdi-white-balance-sunny";
      localStorage.setItem("dark", "true");
    },
    disableDarkMode() {
      this.$vuetify.theme.dark = false;
      this.icon = "mdi-weather-night";
      localStorage.setItem("dark", "false");
    },
    darkModeToggle() {
      this.darkMode = localStorage.getItem("dark");
      if (this.darkMode !== "true") {
        this.enableDarkMode();
      } else {
        this.disableDarkMode();
      }
    },
  },
  mounted() {
    this.darkMode = localStorage.getItem("dark");
    if (this.darkMode === "true") {
      this.enableDarkMode();
    } else this.disableDarkMode();
  },
};
</script>
<style scoped>
.mmmm {
  position: fixed;
  top: 70px;
  right: 20;
  z-index: 99999;
}
</style>