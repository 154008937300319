<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="400">
      <v-card class="pa-4 mt-0" shaped>
        <v-card-text class="headline 5  ">
          chose your langue | إختر لغتك
        </v-card-text>

        <v-divider></v-divider>
        <v-btn class="ma-2" rounded  outlined color="maincolor2" @click="setLocale('en')">
          <v-icon>mdi-translate</v-icon>
          <span>English </span>
        </v-btn>
        <v-btn class="ma-2" outlined color="maincolor2" @click="setLocale('ar')">
          <v-icon>mdi-translate</v-icon>
          <span>عربي</span>
        </v-btn>
        <v-divider class="ma-2"></v-divider>
        <v-card-text >
          <h3>chose your favorit theme | إختر مضهرك المفضل</h3>
        </v-card-text>

        <v-btn class="ma-2" color="maincolor2" rounded outlined @click="darkMode">
          <v-icon>mdi-weather-night</v-icon>
          <span>dark </span>
        </v-btn>
        <v-btn class="ma-2" outlined color="maincolor3" @click="lightMode">
          <v-icon>mdi-white-balance-sunny</v-icon>
          <span>light</span>
        </v-btn>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            {{ $t("nav.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    locale: "en",
    dialog: true,
  }),
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$timeago.locale = locale;
      localStorage.setItem("locale", locale);

      if (locale === "ar") {
        this.$vuetify.rtl = true;
      } else {
        this.$vuetify.rtl = false;
      }
      // TODO: auto reload after locale changing
    },

    darkMode() {
      this.$vuetify.theme.dark = true;
      this.icon = "mdi-white-balance-sunny";
      localStorage.setItem("dark", "true");
    },
    lightMode() {
      this.$vuetify.theme.dark = false;
      this.icon = "mdi-weather-night";
      localStorage.setItem("dark", "false");
    },
  },
  created() {
    let firstLocale = localStorage.getItem("firstlocal");
    if (firstLocale) {
      this.dialog = false;
    }
    let locale = localStorage.getItem("locale");
    this.$i18n.locale = locale;
    this.$timeago.locale = locale;
    if (locale === "ar") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
    localStorage.setItem("firstlocal", "true");
  },
};
</script>
