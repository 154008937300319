import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);
const opts = {};

export default new Vuetify({
  opts,
  rtl: false,
  theme: {
    dark: localStorage.getItem("dark") === "true",
    themes: {
      light: {
        primary: "#008DBD",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        miro: "#e8e8e8",
        colo: "#1E1E1E",
        gold : "#FFD700",
        gold1:"#FFD700",
        gold2:"#F0E68C",
        gold3:"#FFFACD",
        gold4:"#FFA07A",
        gold5:"#FF8C00",
        gold6:"#FFDAB9",
        gold7:"#DB7093",
        maincolor1: "#C40577",
        maincolor2: "#902871",
        maincolor3: "#5A0D5A",
        colorText:"#ffffff"
      },
      dark: {
        primary: "#008DBD",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        miro: "#1E1E1E",
        colo: "#e8e8e8",
        gold : "#FFD700",
        gold1:"#FFD700",
        gold2:"#F0E68C",
        gold3:"#FFFACD",
        gold4:"#FFA07A",
        gold5:"#FF8C00",
        gold6:"#FFDAB9",
        gold7:"#DB7093",
        maincolor1: "#C40577",
        maincolor2: "#902871",
        maincolor3: "#5A0D5A",
        colorText:"#ffffff"
      },
    },
  },
});
