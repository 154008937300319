<template>
<div>




  <v-footer v-bind="localAttrs" class="pa-0 ma-0 maincolor1--text" color="maincolor1">
    
    <!-- <v-card flat tile width="100%" class="light lighten-1 text-center">
      <v-card-text>
        {{ new Date().getFullYear() }} — <strong>sp0mers-team</strong>
      </v-card-text>
    </v-card> -->
      <v-bottom-navigation  class="pt-2">
        
        <router-link to="/">
    <v-btn value="recent">
      <span>{{ $t("dash.home") }}</span>

      <v-icon>mdi-home</v-icon>
    </v-btn>
        </router-link>
        <router-link to="/today">
    <v-btn value="favorites">
      <span>{{ $t("dash.victimtoday") }}</span>

      <v-icon>mdi-history</v-icon>
    </v-btn>
        </router-link>
<router-link to="/victims">
     <v-btn value="victims">
      <span>{{ $t("dash.allvictims") }}</span>

      <v-icon>mdi-view-list-outline</v-icon>
    </v-btn>
</router-link>
<router-link to="/scama">
    <v-btn value="nearby">
      <span>{{ $t("dash.allscama") }}</span>

      <v-icon>mdi-timeline-check</v-icon>
    </v-btn>
</router-link>
  </v-bottom-navigation>
  </v-footer>
</div>
  
</template>
<script>
export default {
  data: () => ({
    icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
    items: ["default", "absolute", "fixed"],
    padless: false,
    variant: "fixed",
     data: () => ({ value: 'recent' }),
  }),
  computed: {
    localAttrs() {
      const attrs = {};

      if (this.variant === "default") {
        attrs.absolute = false;
        attrs.fixed = false;
      } else {
        attrs[this.variant] = true;
      }
      return attrs;
    },
  },
};
</script>
