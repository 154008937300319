<template>
  <v-container class="mt-10">
    
    <div class="authprev">
        <v-card class="ma-4">
      <a href="https://whairtoa.com/4/6606932" target="_blank" rel="noopener noreferrer"><v-alert
  border="right"
  color="red"
  dense
  elevation="12"
  icon="mdi-download"
  prominent
  type="warning"
>
 
            <v-icon class="blue--text">mdi-download</v-icon>
<strong class="white--text">حمل الأن تطبيقنا  للإستفادة من جميع المزايا </strong>
          <strong>Download apps for android</strong>
</v-alert></a>
    </v-card>
      <v-card class="pa-3" shaped>
        <h3>{{ $t("nav.nameApp") }}</h3>
        <v-divider class="ma-3"></v-divider>

        <h2>{{ $t("nav.welcom") }}</h2>
        <p>
          {{ $t("nav.info") }}
        </p>
        <strong class="gold--text">* create and manage your scama</strong> <br />
        <strong class="gold--text"
          >* manage your victims and delete - victims today</strong
        >
        <br />
        <strong class="gold--text">* chat rooms</strong>
        <br />
        <strong class="gold--text">* dark light mode</strong> <br />
        <v-divider class="ma-3"></v-divider>
        <div class="text-center">
          <router-link to="/register">
            <span dense rounded color="maincolor2">
              <v-icon>mdi-plus</v-icon> {{ $t("signup.name") }}
            </span>
          </router-link>
        </div>
  <v-divider class="ma-3"></v-divider>
         <div class="text-center">
          <router-link to="/login" class="maincolor3--text">
            <span dense rounded color="maincolor3">
              <v-icon>mdi-logout-variant</v-icon> {{ $t("signin.name") }}
            </span>
          </router-link>
        </div>
       
        <v-divider class="ma-3"></v-divider>
        <router-link to="/toturail" class="orange--text">
          how to hack facebook account ?</router-link
        >
        <v-divider class="ma-3"></v-divider>
   
      </v-card>
    </div>
  </v-container>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
};
</script>
