import Vue from "vue";
import Vuex from "vuex";
import Auth from "./moduls/auth";
import Post from "./moduls/posts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Post,
  },
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  // modules: {
  // }
});
